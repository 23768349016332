import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import {getUser, setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponentCustom from '../util/asyncComponentCustom';


class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  componentWillReceiveProps(nextProps) {
      // this.props.getUser()
  }
  
  render() {
    const {locale, isDirectionRTL} = this.props;
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <Route exact path={`/campaign`} component={asyncComponentCustom(() => import('app/Campaign/index'))} />
                  <Route path='/lp' component={asyncComponentCustom(() => import('app/campaign'))}/>
                  <Route path='/' component={asyncComponentCustom(() => import('app/index'))}/>
                  <Route component={asyncComponentCustom(() => import('components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {authUser, token, initURL} = auth;
  return {sideNavColor, token, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl, getUser})(App);

